<template>
  <div>

    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{ i18n("Custom.OperationManualDownload") }}
      </span>
    </div>



    <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th>{{ i18n("Custom.FileName") }}</th>
                  <th width="200"></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="book in bookList"
                  :key="book.Guid"
                >
                  <td>{{ book.FileName }}</td>
                  <td>
                    <button class="btn btn-sm btn-primary" @click="download(book.Guid)">
                      {{ i18n("Custom.Download") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>


  </div>
</template>

<script>
export default {
  data() {
    return {
      screen: null, // loading遮罩
      bookList: [],
    };
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
          return this.$t(keyStr);
        },
    //取得重算進度
    getBook() {
      const url = `${window.BaseUrl.api}/Org/usermanual`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
            });
          } else {
            this.bookList = {
              ...response.data.Data,
            };
          }
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
          });
        });
    },

    download(guid) {
      //  下載範例檔

      let params = {
        id: guid,
      };


      const url = `${window.BaseUrl.api}/Org/usermanual/Download`;
      this.screen = this.openLoading();
      this.$http
        .get(url, { params, responseType: "blob" })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            const filename = decodeURIComponent(
              decodeURIComponent(
                response.headers["content-disposition"].split("filename*=UTF-8''")[1]
              )
            );

            const blob = new Blob([response.data]);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          }
          this.screen.close();
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "下載失敗",
            status: "danger",
          });
          this.screen.close();
        });
    },


  },
  created() {
    this.getBook();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ql-editor {
  min-height: 120px;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  object-fit: contain;
}
</style>
